import React, { useEffect, useState } from "react";
// componets
import SplashImage from "../components/auth/SplashImage";
import VerifyLicenseModal from '../components/license/VerifyLicenseModal';
import ModalLicense from "../components/license/ModalLicense";
// assets
import SplashScreen from "../images/banners/banner-black-car.webp";
import authStyles from "../styles/authStyles";
// utils
import showToast from "../utils/toastHelpers";
// hooks
import { useIsMobile, useAnimateOnDesktop } from "../hooks/useIsMobile";
// context
import { useAuth } from "../Context/AuthContext";
// services


const License: React.FC = () => {
    const isMobile = useIsMobile();
    const shouldAnimate = useAnimateOnDesktop(isMobile);
    const { contactData } = useAuth();

    // states
    const [showModalLicenseVerification, setShowModalLicenseVerification] = useState(false);
    const [currentContactData, setCurrentContactData] = useState<any>(null);

    const closeModalLicenseVerification = () => {
        if (contactData?.contact?.idscan_request_id !== currentContactData?.contact?.idscan_request_id || contactData?.contact?.idscan_status !== currentContactData?.contact?.idscan_status) {
            setCurrentContactData(contactData);
        }
        setShowModalLicenseVerification(false);
    };

    const openModal = () => {
        if (currentContactData?.contact) {
            if (!currentContactData?.contact?.idscan_request_id || !currentContactData?.contact?.idscan_status || currentContactData?.contact?.idscan_status?.toLocaleLowerCase() === 'failed') {
                // show the Modal to verify the license
                setShowModalLicenseVerification(true);
            } else if (currentContactData?.contact?.idscan_status?.toLocaleLowerCase() === 'pending' && currentContactData?.contact?.idscan_request_id) {
                showToast('Your license is under review, we will notify you when it is ready', 'info');
            } else if (currentContactData?.contact?.idscan_status?.toLocaleLowerCase() === 'completed' && currentContactData?.contact?.idscan_request_id) {
                showToast('Your license has already been verified', 'info');
                // close the Modal to verify the license
                setShowModalLicenseVerification(false);
            } else {
                showToast('An error occurred please try again or contact support', 'error');
            }
        } else {
            showToast('Loading license and contact data, please try again later', 'error');
            setShowModalLicenseVerification(false);
        }
    };

    useEffect(() => {
        if (contactData?.contact) {
            setCurrentContactData(contactData);
        }
    }, [contactData]);

    return (
        <div style={authStyles.commonStyles.container}>
            <SplashImage isMobile={isMobile} animate={shouldAnimate} SplashScreen={SplashScreen} />
            <div style={{ ...authStyles.commonStyles.formPart, transform: isMobile ? 'translateX(0%)' : (shouldAnimate ? 'translateX(0%)' : 'translateX(-100%)') }}>
                <h2 style={authStyles.commonStyles.center}>License Verification Required</h2>
                <p style={{ ...authStyles.commonStyles.center, width: '80%', marginBottom: '30px' }}>
                    Hey!, we require all users to verify their driving license before renting cars. This helps us maintain the integrity of our platform and foster trust among our users. Click on the button below to start the verification process.
                </p>
                <div style={{ ...authStyles.commonStyles.center, ...authStyles.commonStyles.buttonContent, margin: '20px' }}>
                    <button type='button' className="buttonBlack" onClick={openModal}>Verify your License</button>
                </div>
            </div>
            <ModalLicense isVisible={showModalLicenseVerification} onClose={closeModalLicenseVerification}>
                <VerifyLicenseModal contactData={currentContactData}/>
            </ModalLicense>
        </div>
    );
}


export default License;