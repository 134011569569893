import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// assets
import noImage from '../images/no-image-available.png';
import placeholder from '../images/place-holder.png';
// import carIcon from '../images/icons/car-icon.png';
import chargeIcon from '../images/icons/station-icon.png';
import moneyIcon from '../images/fees.png';
import addressIcon from '../images/fa-solid_directions.png';
import doorIcon from '../images/icons/door-icon.png';
import seatIcon from '../images/icons/seats-icon.png';
// styles
import 'react-lazy-load-image-component/src/effects/blur.css';
import styles from '../styles/confirmStyles.module.css';
// components
import Loader from '../components/Loader';
import VehiclesViewOnMap from '../components/vehicles/VehiclesViewOnMap';
// utils
import showToast from '../utils/toastHelpers';
import { authHelpers } from '../utils/authHelpers';
import { getCID } from '../utils/getCID';
import { sendConversion } from '../utils/sendConversion';
// hooks
import { useIsMobile } from '../hooks/useIsMobile';
// context
import { useAuth } from "../Context/AuthContext";
// firebase
// import { ref, get } from '@firebase/database';
// import { rtdbFleetCars, rtdbStations } from '../firebase/firebase';
// services
import bundeeRestApi from '../services/BundeeRestApi';
import fluxRestApi from '../services/FluxRestApi';
// others
import moment from 'moment-timezone';
// stripe
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import ModalComponentConfirm from '../components/confirm/ModalComponentConfirm';

const Confirm: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const isMobile = useIsMobile();
    const { contactData, user } = useAuth();
    const stripe = useStripe();
    const elements = useElements();

    const queryParams = new URLSearchParams(location.search);
    const startDate = queryParams.get('startDate');
    const endDate = queryParams.get('endDate');
    const pickUpTime = queryParams.get('pickUpTime');
    const returnTime = queryParams.get('returnTime');
    const carId = queryParams.get('carId');

    //states
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    });
    const [carData, setCarData] = useState<any>(null);
    const [dataSet, setDataSet] = useState<any>(null);
    const [prices, setPrices] = useState<any>(null);
    const [customerId, setCustomerId] = useState<string | null>(null);
    const [bundeeId, setBundeeId] = useState<string | null>(null);
    const [hubSpotId, setHubSpotId] = useState<string | null>(null);
    // State for existing payment method
    const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any | null>(null);
    // Modal Confirm
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [bookingData, setBookingData] = useState<any | null>(null);

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const cardElementOptions = {
        style: {
            base: {
                fontSize: '16px',
                color: '#333',
                backgroundColor: '#fff',
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '10px',
                '::placeholder': {
                    color: '#999',
                },
            },
            invalid: {
                color: '#e63946',
                '::placeholder': {
                    color: '#e63946',
                },
            },
            complete: {
                color: '#30ae69',
            },
        },
    };

    // funtions
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // make sure the email is alwas lowercase to avoid case sensitive issues
        if (e.target.name === 'email') {
            e.target.value = e.target.value.trim().toLowerCase();
        }

        // make sure that phone are only numbers
        if (e.target.name === 'phone') {
            e.target.value = e.target.value.replace(/\D/g, '');
        }

        // phone number should be 10 digits
        if (e.target.name === 'phone' && e.target.value.length > 10) {
            showToast('Phone number should be 10 digits', 'error');
            return;
        }

        // make sure name & last name don't include like numbers
        if ((e.target.name === 'firstName' || e.target.name === 'lastName') && /\d/.test(e.target.value)) {
            showToast('Names cannot contain numbers', 'error');
            return;
        }


        setUserData({
            ...userData,
            [e.target.name]: e.target.value
        });
    };

    // Function to create a new user
    const createUser = async (email: string, password: string, phone_number: string, first_name: string, last_name: string) => {
        try {
            const response = await fluxRestApi.createUser(email, password, phone_number, false, false, first_name, last_name, true, false);
            if (response?.data) {
                return response;
            } else {
                return null;
            }
        } catch (error) {
            console.error('Error creating user:', error);
            return null;
        }
    };

    const checkUserByEmail = async (email: string) => {
        try {
            const response = await fluxRestApi.getContactDataByEmail(email);
            if (response?.data?.contact) {
                return response;
            } else {
                return null;
            }
        } catch (error) {
            console.error('Error checking user by email:', error);
            return null;
        }
    };

    const checkUserByPhone = async (phone: string) => {
        try {
             // check the phone number is already registered
            let phoneNumber =  "1" + phone
            const response = await fluxRestApi.getContactDataByPhone(phoneNumber);
            if (response?.data?.user_exists) {
                return true;
            } else {
                return null;
            }
        } catch (error) {
            console.error('Error checking user by phone:', error);
            return null;
        }
    };

    const createBundeeUser = async () => {
        if (!userData.firstName || !userData.lastName || !userData.email || !userData.phone) {
            showToast('Please fill in all the fields', 'error');
            return;
        }
        // create a bundee user
        try {
            const data = {
                "firstname": userData.firstName,
                "lastname": userData.lastName,
                "email": userData.email,
                "mobilephone": `+1${userData.phone}`,
                "userRole": "Driver",
                "channelName": "Flux"
            }
            const response = await bundeeRestApi.createBundeeUser(data);
            if (response?.data?.errorCode === '0' && response?.data?.userResponses[0]?.iduser) {
                // console.log('bundee_user_id created successfully');
                const bundee_user_id = `${response?.data?.userResponses[0]?.iduser}`;
                return bundee_user_id;
            }
            else {
                console.log('Something went wrong with creation bundee_user_id');
                return null
            }
        } catch (error) {
            console.log('Error creating bundee_user_id:', error);
            return null
        }
    };

    const getBundeeUserId = async (email: string) => {
        try {
            const response = await bundeeRestApi.getUserByEmail(email);
            if (response?.data?.userResponse?.iduser) {
                // console.log('bundee_user_id exist');
                const bundee_user_id = `${response?.userResponse?.iduser}`;
                return bundee_user_id;
            } else {
                const bundee_user_id = await createBundeeUser();
                return bundee_user_id || null;
            }
        } catch (e: any) {
            console.log('Something went wrong getting bundee_user_id');
            return null
        }
    }

    const handleBooking = async () => {
        const { paymentMethodId, customerId, bundeeId, Id } = bookingData;
        setIsModalVisible(false);
        setLoading(true);

        if (!carData || !prices) {
            showToast('Car or prices not found', 'error');
            setLoading(false);
            return;
        }

        if (!userData.firstName || !userData.lastName || !userData.email || !userData.phone) {
            showToast('Please fill in all the fields', 'error');
            setLoading(false);
            return;
        }

        if (!paymentMethodId) {
            showToast('Something went wrong with the payment method, please try again', 'error');
            setLoading(false);
            return;
        }

        if (!customerId) {
            // console.log('Customer ID not found in the handleBooking');
            showToast('Something went wrong, please try again', 'error');
            setLoading(false);
            return;
        }

        if (!Id) {
            // console.log('HubSpot ID not found in the handleBooking');
            showToast('Something went wrong, please try again', 'error');
            setLoading(false);
            return;
        }

        try {
            let bundee_user_id = bundeeId || null;
            if (!bundee_user_id) {
                // get or create bundee user
                bundee_user_id = await getBundeeUserId(userData.email);
                if (!bundee_user_id) {
                    console.log('A problem occurred getting or creating bundee_user_id');
                    showToast('Sorry something went wrong, please try again', 'error');
                    setLoading(false);
                    return;
                }
                // update the bundee user id in the database
                const updateContact = await fluxRestApi.updateBundeeUserId(Id, bundee_user_id);
                if (!updateContact) {
                    console.log('Error updating bundee_user_id in the database');
                    showToast('Sorry something went wrong, please try again', 'error');
                    setLoading(false);
                    return;
                }
            }

            // console.log('bundee_user_id:', bundee_user_id);

            const pricesForm = {
                tripamount: prices?.tripAmount,
                taxAmount: prices?.taxAmount,
                tripTaxAmount: prices?.tripTaxAmount,
                totalamount: prices?.totalAmount,
                authorizationamount: prices?.authAmount,
                authorizationpercentage: prices?.authPercentage,
                perDayAmount: prices?.pricePerDay,
                totalDays: prices?.numberOfDays,
                discountPercentage: prices?.discountPercentage,
                discountAmount: prices?.discountAmount,
                tripDiscountedAmount: prices?.tripDiscountedAmount,
                upCharges: prices?.upcharges,
                deliveryCost: prices?.delivery,
                tripFee: prices?.tripFee,
                charges: prices?.charges,
                taxPercentage: prices?.taxPercentage,
                numberOfDaysDiscount: prices?.numberOfDaysDiscount,
                registrationRecoveryFee: prices?.registrationRecoveryFee,
                registrationFee: prices?.registrationFee,
                averageRentalDays: prices?.averageRentalDays,
                concessionFee: prices?.concessionFee,
                concessionPercentage: prices?.concessionPercentage,
                concessionCalculated: prices?.concessionCalculated,
                tripFeeAmount: prices?.tripFeeAmount,
                Statesurchargetax: prices?.stateSurchargeTax,
                Statesurchargeamount: prices?.stateSurchargeAmount,
            }

            const reservationData = {
                ...pricesForm,
                "vehicleid": carData.id,
                "userId": bundee_user_id,
                "startTime":  moment.tz(`${startDate} ${pickUpTime}`, 'YYYY-MM-DD h:mm:ss', userTimezone).utc().toISOString(),
                "endTime": moment.tz(`${endDate} ${returnTime}`, 'YYYY-MM-DD h:mm:ss', userTimezone).utc().toISOString(),
                "pickupTime": pickUpTime,
                "dropTime": returnTime,
                // stripe info
                "stripePaymentToken": "NA",
                "customerToken": customerId,
                "stripePaymentTransactionDetail": "{ \"key1\" : \"val1\" }",
                "stripePaymentID": "NA",
                "paymentMethodIDToken": paymentMethodId,
                "setupIntentToken": "NA",
                "isCustomerTokenNew": "NA",
                // station info
                "longitude": dataSet.geometry.coordinates[0],
                "latitude": dataSet.geometry.coordinates[1],
                "zipCode": dataSet.properties.zip,
                "address1": dataSet.properties.address,
                "channelName": "Flux",
                "airportDelivery": false,
                "deductionfrequencyconfigid": 1,
                "paymentauthorizationconfigid": 0,
                "extreaMilageCost": 0,
                "refundAmount": 0,
                "comments": "",
                "address2": "",
                "cityName": "",
                "country": "",
                "state": "",
                "delivery": false,
            }
            const response = await bundeeRestApi.createReservation(reservationData);
            if (response?.data?.errorCode === '0') {
                if (response?.data?.activeTrips?.length > 0) {
                    showToast('Reservation requested!', 'success');

                    // send notification to support
                    const readAbleStartDate = moment.tz(`${startDate} ${pickUpTime}`, 'YYYY-MM-DD h:mm:ss', userTimezone).format('MMMM Do YYYY, h:mm A')
                    const carInfoNotification = `${carData?.make || ''} ${carData?.model || ''} ${carData?.color || ''} - Plate: ${carData?.number || ''}`;
                    const userInfoNotification = `${userData?.firstName || userData?.email} ${userData?.lastName || ''}`;
                    const data = {
                        "title": `TripId:${response?.data?.activeTrips[0]?.tripid}`,
                        "body": `New Reservation: ${response?.data?.activeTrips[0]?.tripid} | From: ${userInfoNotification} | Car: ${carInfoNotification} | StartTime: ${readAbleStartDate} | Device: Web`,
                        "send_sms": true,
                        "send_email": true,
                    }
                    const sendNotificationToSupport = await fluxRestApi.sendNotificationToSupportUsers(data.title, data.body, data.send_sms, data.send_email);
                    if (!sendNotificationToSupport) {
                        console.log('Error sending notification to support');
                    }

                    // if is an affiliate user
                    try {
                        const cid = getCID();
                        if (cid) {
                            const customFile = {
                                advS1: `${carData?.id}`,
                                advS2: `${response?.data?.activeTrips[0]?.tripid}`,
                                advS3: `${userData?.email || ''}`,
                            }
                            await sendConversion(cid, `${pricesForm.tripTaxAmount?.toFixed(2) || ''}`, customFile);
                            console.log('Trackdesk conversion event triggered');
                        } else {
                            console.error('CID not found');
                        }
                    } catch (error) {
                        console.error('Error sending conversion:', error);
                    }

                    // Move the loading state change to just before navigation
                    setLoading(false);
                    // Navigate to the ReservationSuccess page
                    navigate(`/reservation-success?tripId=${response?.data?.activeTrips[0]?.tripid}&email=${userData.email}`);
                    return;
                } else{
                    showToast('Something went wrong with the reservation', 'error');
                    console.log('Error creating reservation:', response);
                    setLoading(false);
                    return;
                }
            } else if (response?.data?.errorCode === '1' && response?.data?.errorMessage === "You have already created Reservation for this Vehicle") {
                showToast('You have already created Reservation for this Vehicle', 'error');
                setLoading(false);
                return;
            } else if (response?.data?.errorCode === '1' && response?.data?.errorMessage === "Processing unsuccessfully") {
                // the payment method doesn't have found or is not valid
                showToast('Please check your payment method has funds available', 'error');
                showToast('or try another payment method', 'error');
                setLoading(false);
                return;
            }
            else {
                showToast('Something went wrong with the reservation', 'error');
                console.log('Error creating reservation:', response);
                setLoading(false);
                return;
            }


        } catch (error) {
            console.error(error);
            showToast('Sorry something went wrong, please try again', 'error');
            setLoading(false);
        }
    };

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        const isAfter7pm = handleIsAfter7pm();
        if (isAfter7pm) {
            const message ='Pick-up time cannot be earlier than tomorrow at 12 PM'
            showToast(message, 'error');
            navigate('/booking');
            return;
        }
        const isBefore8am = handleIsBefore8am();
        if (isBefore8am) {
            const message = 'Pick-up time cannot be earlier than today at 12 PM';
            showToast(message, 'error');
            navigate('/booking');
            return;
        }
        const isBeforeMinTime = handleIfPickUpTimeIsBeforeMinTime();
        if (isBeforeMinTime) {
            showToast('Pick-up time cannot be before than Minimun Time', 'error');
            navigate('/booking');
            return;
        };

        if (!carData || !prices) {
            showToast('Car or prices not found', 'error');
            showToast('if the problem persists, please try to reload the page', 'error');
            setLoading(false);
            return;
        }

        if (!dataSet?.geometry?.coordinates || !dataSet?.properties?.address || !dataSet?.properties?.zip) {
            showToast('Station data not found', 'error');
            showToast('if the problem persists, please try to reload the page', 'error');
            setLoading(false);
            return;
        }

        if (!userData.firstName || !userData.lastName || !userData.email || !userData.phone) {
            showToast('Please fill in all the fields', 'error');
            setLoading(false);
            return;
        }

        if (!authHelpers.isValidPhoneNumber(userData.phone)) {
            showToast("Invalid USA Phone Number", "error");
            setLoading(false);
            return;
        }

        let customer_id = customerId;
        let email = userData.email;
        let phone = userData.phone;
        let first_name = userData.firstName;
        let last_name = userData.lastName;
        let bundee_id = bundeeId;
        let hubspot_id = hubSpotId;

        setLoading(true); // Start loading
        if (selectedPaymentMethod?.id) {
            //showToast('Booking confirmed with existing payment method!', 'success');
            // console.log('selectedPaymentMethod', selectedPaymentMethod);
            // prepaid cards are not allowed
            if (selectedPaymentMethod.card.funding === 'prepaid') {
                showToast('Prepaid cards are not allowed', 'error');
                setLoading(false);
                return;
            };
            const isDebitCard = selectedPaymentMethod.card.funding === 'debit';
            setLoading(false);
            setBookingData({
                paymentMethodId: selectedPaymentMethod.id,
                customerId: customer_id || '',
                bundeeId: bundee_id || '',
                Id: hubspot_id || ''
            });
            setModalMessage(`You have selected a ${isDebitCard ? 'debit ' : ''}card ${selectedPaymentMethod.card.brand.toUpperCase() || ''} **** ${selectedPaymentMethod.card.last4}. ${isDebitCard ? 'A debit hold of $250 will be placed at the start of your trip.' : ''}`);
            setIsModalVisible(true);
            return;
        }

        if (!stripe || !elements) {
            console.log('Stripe or Elements not loaded');
            showToast('Sorry something went wrong, please try again', 'error');
            setLoading(false);
            return;
        }

        if (!email || !phone || !first_name || !last_name) {
            showToast('User data is incomplete, cannot proceed', 'error');
            setLoading(false);
            return;
        }

        if (paymentMethods.length !== 0 && !selectedPaymentMethod?.id) {
            showToast('Please select a payment method', 'error');
            setLoading(false);
            return;
        }

        const cardElement = elements.getElement(CardNumberElement);

        if (!cardElement) {
            console.log('Card Element not found');
            showToast('Sorry something went wrong, please try again', 'error');
            setLoading(false);
            return;
        }

        try {
            // Create a payment method
            const paymentMethod = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    name: `${first_name} ${last_name}`,
                },
            });

            // Handle payment method errors
            if (paymentMethod?.error) {
                handlePaymentMethodError(paymentMethod.error);
                setLoading(false);
                return;
            }

            // don't allow prepaid cards
            if (paymentMethod?.paymentMethod?.card?.funding === 'prepaid') {
                showToast('Prepaid cards are not allowed', 'error');
                setLoading(false);
                return;
            };

            const isDebitCard = paymentMethod?.paymentMethod?.card?.funding === 'debit';

            if (!customer_id) {
                // Check if user exists by email
                const userResponse = await checkUserByEmail(userData.email);
                // Check if user exists by phone
                const userResponsePhone = await checkUserByPhone(userData.phone);
                if (userResponse) {
                    // if the user was found, we don't care to check the phone number because the user is already registered
                    const userDataFromResponse = userResponse.data;

                    if (!userDataFromResponse || !userDataFromResponse.contact || !userDataFromResponse.contact.stripe_id || !userDataFromResponse.contact.email || !userDataFromResponse.contact.phone || !userDataFromResponse.contact.first_name || !userDataFromResponse.contact.last_name || !userDataFromResponse.contact.id) {
                        showToast('User data is incomplete, cannot proceed', 'error');
                        setLoading(false);
                        return;
                    }

                    setUserData({
                        ...userData,
                        email: userDataFromResponse.contact.email,
                        phone: userDataFromResponse.contact.phone.replace(/^\+1/, '')
                    });
                    // Update the user data
                    customer_id = userDataFromResponse.contact.stripe_id;
                    email = userDataFromResponse.contact.email
                    phone = userDataFromResponse.contact.phone.replace(/^\+1/, '');
                    bundee_id = userDataFromResponse?.contact?.bundee_user_id || '';
                    hubspot_id = userDataFromResponse.contact.id

                    // if first name and last name changes update the user data on the back
                    if (userDataFromResponse.contact.first_name !== userData.firstName || userDataFromResponse.contact.last_name !== userData.lastName) {
                        const data = {
                            firstname: userData.firstName,
                            lastname: userData.lastName
                        };
                        const updatedUser = await fluxRestApi.updateStatusAccount(userDataFromResponse.contact.id, data);
                        if (updatedUser === null) {
                            showToast('Sorry something went wrong, please try again', 'error');
                            setLoading(false);
                            return;
                        }
                        first_name = userData.firstName;
                        last_name = userData.lastName;
                        console.log('User data updated')
                    }

                    // showToast('User found and data populated', 'success');
                    console.log('User found and data populated');
                } else {
                    // we need to verify the phone number is not already registered to another user
                    if (userResponsePhone) {
                        showToast('Phone number already registered to another user', 'error');
                        setLoading(false);
                        return;
                    }

                    // Create a new user and add stogn password that we will send trogh email to the user and link to change it
                    const password = Math.random().toString(36).slice(-8);
                    // add +1 to the phone number
                    const phone_number = `+1${phone}`;
                    const newUser = await createUser(email, password, phone_number, first_name, last_name);
                    if (newUser === null) {
                        showToast('Sorry something went wrong, please try again', 'error');
                        setLoading(false);
                        return;
                    }

                    if (!newUser?.data?.flux_properties?.email || !newUser?.data?.flux_properties?.phone || !newUser?.data?.flux_properties?.firstname || !newUser?.data?.flux_properties?.lastname || !newUser?.data?.id || !newUser?.data?.flux_properties?.stripe_id) {
                        showToast('User data is incomplete, cannot proceed', 'error');
                        setLoading(false);
                        return;
                    }

                    // update the customer id
                    customer_id = newUser.data.flux_properties.stripe_id;
                    hubspot_id = newUser.data.id;
                    console.log('User created successfully');
                }
            }

            // get the payment methods
            const getPaymentMethods = await fluxRestApi.getPaymentMethods(customer_id as string);
            if (getPaymentMethods?.data?.payment_methods?.length) {
                // check if the payment method match with one of the existing payment methods
                const existingPaymentMethod = getPaymentMethods.data.payment_methods.find((method: any) => method.card.last4 === paymentMethod?.paymentMethod?.card?.last4 && method.card.brand === paymentMethod?.paymentMethod?.card?.brand);
                if (existingPaymentMethod) {
                    // showToast('Payment method already exists!', 'success');
                    // console.log('Payment Method ID:', existingPaymentMethod.id);
                    if (existingPaymentMethod.card.funding === 'prepaid') {
                        showToast('Prepaid cards are not allowed', 'error');
                        setLoading(false);
                        return;
                    };
                    const isDebitCard = existingPaymentMethod.card.funding === 'debit';
                    setBookingData({
                        paymentMethodId: existingPaymentMethod.id,
                        customerId: customer_id || '',
                        bundeeId: bundee_id || '',
                        Id: hubspot_id || ''
                    });
                    setModalMessage(`You have selected a ${isDebitCard ? 'debit ' : ''}card ${existingPaymentMethod.card.brand.toUpperCase() || ''} **** ${existingPaymentMethod.card.last4}. ${isDebitCard ? 'A debit hold of $250 will be placed at the start of your trip.' : ''}`);
                    setIsModalVisible(true);
                    setLoading(false);
                    return;
                }
            }


            // Request the client_secret from the backend
            const response = await fluxRestApi.createSetupIntent({
                customer_id: customer_id,
            });
            if (!response?.data?.setup_intent || !response?.data?.client_secret) {
                showToast('Sorry something went wrong with the payment setup', 'error');
                setLoading(false);
                return;
            }

            // Confirm the payment setup
            const setupIntent = await stripe.confirmCardSetup(response.data.client_secret, {
                payment_method: paymentMethod.paymentMethod.id,
            });

            if (setupIntent.error) {
                console.log(setupIntent.error);
                showToast('something went wrong setupIntent, please try again', 'error');
                setLoading(false);
                return;
            }

            // showToast('Payment method created successfully!', 'success');
            // console.log('Payment Method ID:', setupIntent.setupIntent.payment_method);

            setBookingData({
                paymentMethodId: setupIntent?.setupIntent?.payment_method?.toString(),
                customerId: customer_id || '',
                bundeeId: bundee_id || '',
                Id: hubspot_id || ''
            });
            setModalMessage(`You have selected a ${isDebitCard ? 'debit ' : ''}card ${paymentMethod?.paymentMethod?.card?.brand || ''} **** ${paymentMethod?.paymentMethod?.card?.last4}. ${isDebitCard ? 'A debit hold of $250 will be placed at the start of your trip.' : ''}`);
            setIsModalVisible(true);
            setLoading(false);
        } catch (error) {
            console.log(error);
            showToast('Sorry something went wrong, please try again', 'error');
            setLoading(false);
        }
    };

    const formatDate = (date: string, time: string) => {
        return moment(`${date} ${time}`, 'YYYY-MM-DD hh:mm:ss').format('dddd, MMMM Do YYYY, h:mm A');
    };

    const handleFeatureClick = (feature: any) => {
        if (!feature?.geometry?.coordinates) return;
        // in new tab open google maps
        window.open(`https://www.google.com/maps/search/?api=1&query=${feature.geometry.coordinates[1]},${feature.geometry.coordinates[0]}`, '_blank');
    };

    const handleUseExistingPaymentMethod = (paymentMethod: any) => {
        setSelectedPaymentMethod(paymentMethod);
        showToast('Payment method selected!, please confirm booking', 'success');
    };

    const handleUseAnotherCard = () => {
        setPaymentMethods([]);
    };

    const handlePaymentMethodError = (error: any) => {
        switch (error.code) {
            case 'incomplete_number':
                showToast('The card number is incomplete', 'error');
                break;
            case 'invalid_number':
                showToast('The card number is invalid', 'error');
                break;
            case 'incomplete_expiry':
                showToast('The expiration date is incomplete', 'error');
                break;
            case 'incomplete_cvc':
                showToast('The CVC is incomplete', 'error');
                break;
            case 'invalid_expiry_year_past':
                showToast('The expiration date is in the past', 'error');
                break;
            case 'expired_card':
                showToast('The card has expired', 'error');
                break;
            case 'incorrect_number':
                showToast('The card number is incorrect', 'error');
                break;
            case 'incorrect_cvc':
                showToast('The CVC number is incorrect', 'error');
                break;
            case 'insufficient_funds':
                showToast('Insufficient funds in the card', 'error');
                break;
            case 'invalid_amount':
                showToast('The payment amount is invalid or exceeds the allowed amount', 'error');
                break;
            default:
                showToast('Sorry something went wrong with your card, please try again', 'error');
                showToast('or try another card', 'error');
                console.log(error);
        }
    };

    const handleIsAfter7pm = useCallback(() => {
        const selectedStartDateTime = moment(`${startDate} ${pickUpTime}`, 'YYYY-MM-DD HH:mm:ss');

        const now = moment();
        const isAfter7pm = now.hour() >= 19;

        if (!isAfter7pm) return false;

        // Set the minimum allowed pick-up date and time
        const minAllowedDateTime = moment().add(1, 'day').startOf('day').add(12, 'hours') // Tomorrow at 12 PM

        // Validate the selected pick-up time
        if (selectedStartDateTime.isBefore(minAllowedDateTime)) {
            return true
        };
        return false
    }, [startDate, pickUpTime]);

    const handleIsBefore8am = useCallback(() => {
        const selectedStartDateTime = moment(`${startDate} ${pickUpTime}`, 'YYYY-MM-DD HH:mm:ss');

        const now = moment();
        const isBefore8am = now.hour() < 8;

        if (!isBefore8am) return false;

        // Set the minimum allowed pick-up date and time
        const minAllowedDateTime = moment().startOf('day').add(12, 'hours') // Today at 12 PM

        // Validate the selected pick-up time
        if (selectedStartDateTime.isBefore(minAllowedDateTime)) {
            return true
        };
        return false
    }, [startDate, pickUpTime]);

    const handleIfPickUpTimeIsBeforeMinTime = useCallback(() => {
        const currentMinDateTime = moment().add(3, 'hours').add(10, 'minutes');
        const selectedStartDateTime = moment(`${startDate} ${pickUpTime}`, 'YYYY-MM-DD HH:mm:ss');

        if (selectedStartDateTime.isSame(currentMinDateTime, 'day') && selectedStartDateTime.isBefore(currentMinDateTime.subtract(10, 'minutes'))) {
            return true;
        }
        return false;
    }, [startDate, pickUpTime]);

    const handleCloseModal = () => {
        setIsModalVisible(false);
        setBookingData(null);
        setModalMessage('');
    }

    // effects
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!startDate || !endDate || !pickUpTime || !returnTime || !carId) {
                    showToast('Please choose the date and time to continue', 'error');
                    navigate('/booking');
                    return;
                }

                const isAfter7pm = handleIsAfter7pm();
                if (isAfter7pm) {
                    const message ='Pick-up time cannot be earlier than tomorrow at 12 PM'
                    showToast(message, 'error');
                    navigate('/booking');
                    return;
                }
                const isBefore8am = handleIsBefore8am();
                if (isBefore8am) {
                    const message = 'Pick-up time cannot be earlier than today at 12 PM';
                    showToast(message, 'error');
                    navigate('/booking');
                    return;
                }
                const isBeforeMinTime = handleIfPickUpTimeIsBeforeMinTime();
                if (isBeforeMinTime) {
                    showToast('Pick-up time cannot be before than Minimun Time', 'error');
                    navigate('/booking');
                    return;
                };


                setLoading(true);

                // Validate car availability
                const availabilityResponse = await bundeeRestApi.getAvailabilityDatesByVehicleId(carId);
                if (availabilityResponse?.data?.errorCode !== '0') {
                    showToast('Something went wrong, please try again', 'error');
                    setLoading(false);
                    navigate('/booking');
                    return;
                }

                const unAvailableDates = availabilityResponse.data?.unAvailabilityDate?.map((date: any) =>  moment.utc(date).tz(userTimezone).toISOString());
                const selectedStartDateTimeISO =  moment.tz(`${startDate} ${pickUpTime}`, 'YYYY-MM-DD h:mm:ss', userTimezone).utc().toISOString();
                const selectedEndDateTimeISO = moment.tz(`${endDate} ${returnTime}`, 'YYYY-MM-DD h:mm:ss', userTimezone).utc().toISOString();

                const isAvailable = unAvailableDates.every((date: any) => moment.utc(date).isBefore(moment.utc(selectedStartDateTimeISO)) || moment.utc(date).isAfter(moment.utc(selectedEndDateTimeISO)));
                if (!isAvailable) {
                    showToast('The selected dates are not available', 'error');
                    setLoading(false);
                    navigate('/booking');
                    return;
                }

                // Get car data
                const carResponse = await bundeeRestApi.getVehicleData(carId);
                if (carResponse?.data?.errorCode !== '0' || !carResponse?.data?.vehicleAllDetails?.length) {
                    showToast('Car not found', 'error');
                    setLoading(false);
                    navigate('/booking');
                    return;
                }

                // const bundeeProperties = carResponse.data.vehicleAllDetails[0];
                // const stationsSnapshot = await get(ref(rtdbStations));
                // if (!stationsSnapshot.exists()) {
                //     showToast('Stations not found', 'error');
                //     setLoading(false);
                //     navigate('/booking');
                //     return;
                // }

                // const stations = stationsSnapshot.val().features;
                // const carsSnapshot = await get(ref(rtdbFleetCars));
                // if (!carsSnapshot.exists()) {
                //     showToast('Car not found', 'error');
                //     setLoading(false);
                //     navigate('/booking');
                //     return;
                // }

                // const fleetCars = carsSnapshot.val();
                // const fluxCar = fleetCars.find((car: any) => car.BundeeActive && car.VIN === bundeeProperties.vin);
                // console.log('fluxCar', fluxCar);
                const car = carResponse?.data?.vehicleAllDetails[0];
                if (!car) {
                    showToast('Car not found', 'error');
                    setLoading(false);
                    navigate('/booking');
                    return;
                }

                // const station = stations.find((s: any) => s.properties.places_id === car.places_id);
                // if (!station) {
                //     showToast('Station not found', 'error');
                //     setLoading(false);
                //     navigate('/booking');
                //     return;
                // }

                setCarData(car);
                setDataSet({
                    geometry: {
                        coordinates: [Number(car.longitude), Number(car.latitude)],
                        type: "Point"
                    },
                    properties: {
                        zip: car.zipcode,
                        address: car.address1,
                        numCars: 1,
                        cars: [car]
                    }
                });
                setLoading(false);
            } catch (error) {
                console.error(error);
                showToast('An error occurred', 'error');
                setLoading(false);
                navigate('/booking');
            }
        };

        fetchData();
    }, [startDate, endDate, pickUpTime, returnTime, carId, navigate, userTimezone, isMobile, handleIsAfter7pm, handleIfPickUpTimeIsBeforeMinTime, handleIsBefore8am]);

    // get the prices
    useEffect(() => {
        const getPrices = async () => {
            try {
                if (!carData) return;
                setLoading(true);
                const data = {
                    "vehicleid": carData.id,
                    "startTime": moment.tz(`${startDate} ${pickUpTime}`, 'YYYY-MM-DD hh:mm:ss', userTimezone).utc().toISOString(),
                    "endTime": moment.tz(`${endDate} ${returnTime}`, 'YYYY-MM-DD hh:mm:ss',userTimezone).utc().toISOString()
                }
                const pricesResponse = await bundeeRestApi.calculatePrices(data);
                if (pricesResponse?.data?.errorCode !== '0' && !pricesResponse?.data?.priceCalculatedList[0]) {
                    showToast('Something went wrong, please try to reload the page', 'error');
                    setLoading(false);
                    setPrices(null);
                    return;
                }

                // console.log('prices', pricesResponse.data.priceCalculatedList[0]);
                setPrices(pricesResponse.data.priceCalculatedList[0]);
                setLoading(false);
            } catch (error) {
                console.error(error);
                showToast('Something went wrong, please try to reload the page', 'error');
                setPrices(null);
                setLoading(false);
            }
        };
        getPrices();
    }, [carData, startDate, endDate, pickUpTime, returnTime, userTimezone]);

    // get the payment methods
    useEffect(() => {
        const fetchPaymentMethods = async () => {
            try {
                if (!customerId) return;
                const response = await fluxRestApi.getPaymentMethods(customerId);
                if (response?.data?.payment_methods?.length) {
                    setPaymentMethods(response.data.payment_methods);
                }
            } catch (error) {
                console.error('Error fetching payment methods:', error);
            }
        };
        if (customerId) {
            fetchPaymentMethods();
        }
    }, [customerId]);

    // set user data if the user is logged in
    useEffect(() => {
        if (user && contactData) {
            // console.log('contactData', contactData);
            setUserData({
                firstName: (contactData?.contact?.first_name && contactData?.contact?.first_name !== '-') ? contactData.contact.first_name : '',
                lastName: (contactData?.contact?.last_name && contactData?.contact?.last_name !== '-') ? contactData.contact.last_name : '',
                email: contactData?.contact?.email ? contactData.contact.email : '',
                phone: contactData?.contact?.phone ? contactData.contact.phone.replace(/^\+1/, '') : ''
            });
            setCustomerId(contactData?.contact?.stripe_id || null);
            if (contactData?.contact?.bundee_user_id) {
                setBundeeId(contactData.contact.bundee_user_id);
            }
            setHubSpotId(contactData?.contact?.id || '');
        }
    }, [user, contactData]);


    return (
        <>
        <div className={styles.container}>
            {loading && <Loader loading={loading} />}
            {/* Car Details */}
            <div className={styles.card}>
                <h2 className='text-lg font-bold'>{carData ? `${carData?.make || ''} ${carData?.model || ''} - ${carData?.color || ''}` : 'Loading...'}</h2>
                <div className={isMobile ? styles.carDetailsMobile : styles.carDetails}>
                    <LazyLoadImage
                        src={carData ? carData?.imageresponse[0]?.imagename : placeholder}
                        alt="Car Image"
                        effect="blur"
                        placeholderSrc={placeholder}
                        className={styles.carImage}
                        onError={(e) => {
                            e.currentTarget.src = noImage;
                        }}
                    />
                    <div className={styles.content}>
                        <div className={styles.iconContainer}>
                            <LazyLoadImage
                                src={chargeIcon}
                                alt="Charge Icon"
                                effect="blur"
                                placeholderSrc={placeholder}
                                className={styles.icon}
                            />
                            <span className={styles.dataCar}>{carData?.name ? carData?.name : '...'}</span>
                        </div>
                        <div className={styles.iconContainer}>
                            <LazyLoadImage
                                src={doorIcon}
                                alt="door Icon"
                                effect="blur"
                                placeholderSrc={placeholder}
                                className={styles.icon}
                            />
                            <span className={styles.dataCar}>Doors: {carData?.doors ? carData?.doors : ''}</span>
                        </div>
                        <div className={styles.iconContainer}>
                            <LazyLoadImage
                                src={seatIcon}
                                alt="seats Icon"
                                effect="blur"
                                placeholderSrc={placeholder}
                                className={styles.icon}
                            />
                            <span className={styles.dataCar}>Seats: {carData?.seatingCapacity ? carData?.seatingCapacity : ''}</span>
                        </div>
                        <div className={styles.iconContainer}>
                            <LazyLoadImage
                                src={moneyIcon}
                                alt="Money Icon"
                                effect="blur"
                                placeholderSrc={placeholder}
                                className={styles.icon}
                            />
                            <span className={styles.dataCar}>${prices?.pricePerDay || '...'} / day</span>
                        </div>
                    </div>
                </div>
                <div className={styles.rentalDetails}>
                    <div className={styles.priceItem}>
                        <span>Rental (${prices?.pricePerDay || '...'} x {prices?.numberOfDays || '...'} days)</span>
                        <span>${prices ? prices.pricePerDay * prices.numberOfDays : '...'}</span>
                    </div>
                    <div className={styles.priceItem}>
                        <span>Trip Fee</span>
                        <span>${prices?.tripFee || '...'}</span>
                    </div>
                    <div className={styles.priceItem}>
                        <span>Sales Taxes ({prices ? prices.taxPercentage : '...'}%)</span>
                        <span>${prices?.taxAmount?.toFixed(2) || '...'}</span>
                    </div>
                    <div className={styles.priceItem}>
                        <span>Total Rental Charge</span>
                        <span>${prices?.tripTaxAmount?.toFixed(2) || '...'}</span>
                    </div>
                </div>
            </div>

            {/* Trip Details */}
            <div className={styles.card}>
                <h2 className='text-lg font-bold'>Trip Details</h2>
                <div className={styles.details}>
                    <p style={{ margin: 0 }}><strong>From:</strong> {(startDate && pickUpTime) ? formatDate(startDate, pickUpTime) : '...'}</p>
                    <p><strong>To:</strong> {(endDate && returnTime) ? formatDate(endDate, returnTime) : '...'}</p>
                    {/* <div className={styles.iconContainer}>
                        <LazyLoadImage
                            src={carIcon}
                            alt="Car Icon"
                            effect="blur"
                            placeholderSrc={placeholder}
                            className={styles.icon}
                        />
                        <span className={styles.dataCar}>{dataSet?.properties?.flux_name || '...'}</span>
                    </div> */}
                    <div className={styles.iconContainer}>
                        <LazyLoadImage
                            src={addressIcon}
                            alt="Address Icon"
                            effect="blur"
                            placeholderSrc={placeholder}
                            className={styles.icon}
                        />
                        <span className={styles.dataCar}>{dataSet?.properties?.address || '...'}</span>
                    </div>
                    <div className={styles.mapContainer}>
                        {dataSet && <VehiclesViewOnMap
                            initialLatitude={dataSet.geometry.coordinates[1]}
                            initialLongitude={dataSet.geometry.coordinates[0]}
                            initialZoom={18}
                            width={isMobile ? '80%' : '100%'}
                            height={'100%'}
                            dataset={[dataSet]}
                            onFeatureClick={handleFeatureClick}
                            gestureHandling={'cooperative'}
                        />}
                    </div>
                </div>
            </div>

            {/* Personal Information */}
            <div className={styles.card}>
                <h2 className='text-lg font-bold'>Personal Information</h2>
                <div className={styles.formGroup}>
                    <input
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={userData.firstName}
                        onChange={handleChange}
                        autoComplete="given-name"
                        readOnly={contactData?.contact?.first_name && contactData?.contact?.first_name !== '-'}
                    />
                    <input
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={userData.lastName}
                        onChange={handleChange}
                        autoComplete="family-name"
                        readOnly={contactData?.contact?.last_name && contactData?.contact?.last_name !== '-'}
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={userData.email}
                        onChange={handleChange}
                        autoComplete="email"
                        readOnly={user != null}
                    />
                    <div className={styles.phoneGroup}>
                        <span>+1</span>
                        <input
                            type="tel"
                            name="phone"
                            placeholder="Phone Number"
                            value={userData.phone}
                            onChange={handleChange}
                            autoComplete="tel"
                            readOnly={user != null}
                        />
                    </div>
                </div>
                {user && <p>To update your information, please visit your profile settings.</p>}
            </div>


            {/* Payment Information */}
            <div className={styles.card}>
                <h2 className='text-lg font-bold mb-2'>Payment Information</h2>
                <p className='text-sm text-gray-500 mb-4'>Note: A refundable security deposit of $250 will be authorized on all modes of payment.</p>
                {paymentMethods.length > 0 ? (
                    <div>
                        <h3>Select an existing payment method:</h3>
                        {paymentMethods.map((method) => (
                            <div key={method.id} className={styles.paymentMethod}>
                                <span>{method.card.brand} **** **** **** {method.card.last4}</span>
                                <button className={styles.useButton} onClick={() => handleUseExistingPaymentMethod(method)}>Use this</button>
                            </div>
                        ))}
                        <button className={styles.anotherCardButton} onClick={handleUseAnotherCard}>Use another card</button>
                    </div>
                ) : (
                    <div className={styles.formGroup}>
                        <label>Card Number</label>
                        <CardNumberElement className={styles.stripeElement} options={cardElementOptions} />

                        <label>Expiry Date</label>
                        <CardExpiryElement className={styles.stripeElement} options={cardElementOptions} />

                        <label>CVC</label>
                        <CardCvcElement className={styles.stripeElement} options={cardElementOptions} />
                    </div>
                )}
            </div>

            {/* Submit Button */}
            <div className={isMobile ? styles.buttonContainerMobile : styles.buttonContainer}>
                <button className={styles.button} onClick={(e) => handleSubmit(e)}>Confirm Booking</button>
            </div>
        </div>
        <ModalComponentConfirm
            isVisible={isModalVisible}
            onClose={handleCloseModal}
        >
            <div className={styles.modalContent}>
                <h2 className='text-lg font-bold'>Confirm Booking</h2>
                <p>{modalMessage}</p>
                <div className={styles.modalButtons}>
                    <button className={styles.cancelButton} onClick={handleCloseModal}>Cancel</button>
                    <button className={styles.confirmButton} onClick={handleBooking}>Confirm</button>
                </div>
            </div>

        </ModalComponentConfirm>
        </>
    );
};

export default Confirm;
